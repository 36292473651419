import React, { useState } from 'react';
import './App.css';
import MindARThreeViewer from './alcon-ar-three';

function App() {
  const [showVideoButton, setShowVideoButton] = useState(false)
  const [showStartButton, setShowStartButton] = useState(true)

  const handleVideoLink = () => {
    window.location.href = "https://embed.ustudio.com/embed/DFTtFcuDVDR5/U3UtTz6eLyzn"
  }

  return (
    <div className="App">
      <video id="cover_water" loop preload="auto" crossOrigin="anonymous" playsInline style={{ display: "none" }}>
        <source src="Water_transition_V03.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
      </video>
      <video id="contact_lens" loop preload="auto" crossOrigin="anonymous" playsInline style={{ display: "none" }}>
        <source src="Contact_lens_caustics_V02.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
      </video>
      <video id="wall_video" loop preload="auto" crossOrigin="anonymous" playsInline style={{ display: "none" }}>
        <source src="Wall_caustics_V06.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
      </video>
      {/* <div className="reset-button">
        <button className='btn' onClick={() => window.location.href = window.location.href}>
          Recalibrar
        </button>
      </div> */}
      <div className="control-buttons">
        <button id="startAnimatingButton" className='btn' style={
          showStartButton ? {
            visibility: "visible",
            opacity: 1
          } : {
            visibility: "hidden",
            opacity: 0
          }}>
            Ativar
          </button>
        <button id="viewVideo" className='btn' onClick={handleVideoLink} style={
          showVideoButton ? {
            visibility: "visible",
            opacity: 1
          } : {
            visibility: "hidden",
            opacity: 0
          }}>
            Ver vídeo
          </button> 
      </div>
      <div className="container" style={{width: "100vw", height: "100vh", position: "relative", overflow: "hidden"}}>
        <MindARThreeViewer setShowVideoButton={setShowVideoButton} setShowStartButton={setShowStartButton} />
      </div>
    </div>
  );
}

export default App;