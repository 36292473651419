/* eslint-disable import/no-anonymous-default-export */
import React, { useLayoutEffect, useRef } from "react";
import { MindARThree } from "mind-ar/dist/mindar-image-three.prod.js";
import * as THREE from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";

export default ({ setShowVideoButton, setShowStartButton }) => {
  const containerRef = useRef(null);
  const mindarThreeRef = useRef(null);

  const clock = new THREE.Clock();

  let animationActions = [];
  let animationMixers = [];
  let video, texture;

  const start = async () => {
    await mindarThreeRef.current.start();
    mindarThreeRef.current.renderer.setAnimationLoop(() => {
      let delta = clock.getDelta();
      animationMixers.forEach((mixer) => {
        mixer.update(delta);
      });

      mindarThreeRef.current.renderer.toneMappingExposure = 2;
      mindarThreeRef.current.renderer.toneMapping = THREE.ACESFilmicToneMapping;
      mindarThreeRef.current.renderer.outputEncoding = THREE.SRGBColorSpace;

      mindarThreeRef.current.renderer.render(
        mindarThreeRef.current.scene,
        mindarThreeRef.current.camera
      );
    });
  };

  useLayoutEffect(() => {
    // document
    //   .querySelector("#startAnimatingButton")
    //   .addEventListener("click", () => {
    //     setShowVideoButton(false);
    //     setShowStartButton(false);
    //     document.getElementById("contact_lens").currentTime = 0;
    //     document.getElementById("cover_water").currentTime = 0;
    //     document.getElementById("wall_video").currentTime = 0;

    //     sound.stop();
    //     setTimeout(() => {
    //       document.getElementById("contact_lens").play();
    //       document.getElementById("cover_water").play();
    //       document.getElementById("wall_video").play();

    //       animationActions.forEach((action) => {
    //         action.clampWhenFinished = true;
    //         action.stop();
    //         if (action._clip.name != "water_cover") {
    //           action.timeScale = 0.5;
    //         }
    //         action.setLoop(THREE.LoopOnce).play();
    //       });

    //       setTimeout(() => {
    //         setShowVideoButton(true);
    //       }, 43_000);

    //       sound.play();
    //     }, 3000);
    //   });

    mindarThreeRef.current = new MindARThree({
      container: containerRef.current,
      imageTargetSrc: "targets.mind",
      uiScanning: false,
      filterMinCF: 0.001,
      filterBeta: 0.001,
    });

    mindarThreeRef.current.renderer.toneMapping = THREE.ACESFilmicToneMapping;

    const anchor = mindarThreeRef.current.addAnchor(0);

    const GROUP_ROTATION = (90 * Math.PI) / 180;
    const GLOBAL_SCALE = 0.052;
    const GLOBAL_SCALE_WELL = 0.055;
    const GLOBAL_TRANSLATION = 0.015;

    // // create an AudioListener and add it to the camera
    // const listener = new THREE.AudioListener();
    // mindarThreeRef.current.camera.add( listener );

    // // create a global audio source
    // const sound = new THREE.Audio( listener );

    // // load a sound and set it as the Audio object's buffer
    // const audioLoader = new THREE.AudioLoader();
    // audioLoader.load('ALCON_VR_v05_AUDIO_mixdown.mp3', function( buffer ) {
    //   sound.setBuffer( buffer );
    //   sound.setVolume( 0.5 );
    // });

    const listener = new THREE.AudioListener();
    mindarThreeRef.current.camera.add(listener);

    let sound;

    function startAR() {
      setShowVideoButton(false);
      setShowStartButton(false);
      document.getElementById("contact_lens").currentTime = 0;
      document.getElementById("cover_water").currentTime = 0;
      document.getElementById("wall_video").currentTime = 0;

      sound.stop();
      setTimeout(() => {
        document.getElementById("contact_lens").play();
        document.getElementById("cover_water").play();
        document.getElementById("wall_video").play();

        animationActions.forEach((action) => {
          action.clampWhenFinished = true;
          action.stop();
          if (action._clip.name != "water_cover") {
            action.timeScale = 0.5;
          }
          action.setLoop(THREE.LoopOnce).play();
        });

        setTimeout(() => {
          setShowVideoButton(true);
        }, 43_000);

        sound.play();
      }, 3000);
    }

    document
      .querySelector("#startAnimatingButton")
      .addEventListener("click", () => {
        // create a global audio source
        sound = new THREE.Audio(listener);

        // load a sound and set it as the Audio object's buffer
        const audioLoader = new THREE.AudioLoader();
        audioLoader.load("ALCON_VR_v05_AUDIO_mixdown.mp3", function (buffer) {
          sound.setBuffer(buffer);
          sound.setVolume(0.5);
          startAR();
        });
      });

    const fbxLoader = new FBXLoader();

    // MASK
    fbxLoader.load(
      "AR_Surface_Mask_v2.fbx",
      (object) => {
        anchor.group.add(object);
        object.scale.set(
          GLOBAL_SCALE_WELL,
          GLOBAL_SCALE_WELL,
          GLOBAL_SCALE_WELL
        );
        object.translateX(GLOBAL_TRANSLATION);
        object.rotation.set(GROUP_ROTATION, 0, 0);

        object.children[0].material.colorWrite = false;
        object.children[0].renderOrder = -1;
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      (error) => {
        console.log(error);
      }
    );

    // WALLS
    fbxLoader.load(
      "AR_Alcon_Pamphlet_Walls_v2.fbx",
      (object) => {
        object.traverse(function (child) {
          video = document.getElementById("wall_video");
          console.log(video);

          texture = new THREE.VideoTexture(video);
          texture.colorSpace = THREE.SRGBColorSpace;

          child.material = new THREE.MeshBasicMaterial({
            color: 0xffffff,
            map: texture,
          });
        });
        anchor.group.add(object);
        object.scale.set(
          GLOBAL_SCALE_WELL,
          GLOBAL_SCALE_WELL,
          GLOBAL_SCALE_WELL
        );
        object.translateX(GLOBAL_TRANSLATION);
        object.rotation.set(GROUP_ROTATION, 0, 0);
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      (error) => {
        console.log(error);
      }
    );

    // COVER WATER
    fbxLoader.load(
      "AR_Alcon_Pamphlet_Cover_Water_v2.fbx",
      (object) => {
        let mixer = new THREE.AnimationMixer(object);
        let mixer2 = new THREE.AnimationMixer(object.children[0].children[0]);

        const opacityKF = new THREE.NumberKeyframeTrack(
          ".material.opacity",
          [0, 2, 3, 18, 19],
          [0, 0, 1, 1, 0]
        );

        const fadeWaterClip = new THREE.AnimationClip(
          "fade-water-opacity",
          object.animations[0].duration,
          [opacityKF]
        );
        animationActions.push(mixer2.clipAction(fadeWaterClip));

        const animationAction = mixer.clipAction(object.animations[0]);
        animationAction._clip.name = "water_cover";
        animationActions.push(animationAction);
        animationMixers.push(mixer);
        animationMixers.push(mixer2);

        anchor.group.add(object);
        object.scale.set(
          GLOBAL_SCALE_WELL,
          GLOBAL_SCALE_WELL,
          GLOBAL_SCALE_WELL
        );
        object.translateX(GLOBAL_TRANSLATION);
        object.rotation.set(GROUP_ROTATION, 0, 0);

        video = document.getElementById("cover_water");

        texture = new THREE.VideoTexture(video);
        texture.colorSpace = THREE.SRGBColorSpace;

        object.children[0].children[0].material = new THREE.MeshBasicMaterial({
          color: 0xffffff,
          map: texture,
        });
        object.children[0].children[0].material.transparent = true;
        object.children[0].children[0].material.depthWrite = false;
        object.children[0].children[0].material.side = THREE.FrontSide;
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      (error) => {
        console.log(error);
      }
    );

    // COVER SOMOS_AGUA
    fbxLoader.load(
      "AR_Alcon_Pamphlet_Cover_Somos_Agua_v2_PRT.fbx",
      (object) => {
        object.traverse(function (child) {
          if (child.isMesh && child.material) {
            child.material.transparent = false;
          }
        });

        let mixer = new THREE.AnimationMixer(object);
        animationMixers.push(mixer);
        let mixer2 = new THREE.AnimationMixer(object.children[0].children[0]);
        object.children[0].children[0].material.transparent = true;
        object.children[0].children[0].material.depthWrite = false;
        object.children[0].children[0].material.side = THREE.FrontSide;
        animationMixers.push(mixer2);

        const opacityKF = new THREE.NumberKeyframeTrack(
          ".material.opacity",
          [0, 2, 3, 18.5, 19.5],
          [1, 1, 0, 0, 1]
        );

        const fadeWaterClip = new THREE.AnimationClip(
          "fade-cover-opacity",
          object.animations[0].duration,
          [opacityKF]
        );

        animationActions.push(mixer2.clipAction(fadeWaterClip));

        const animationAction = mixer.clipAction(object.animations[0]);
        animationActions.push(animationAction);
        animationMixers.push(mixer);

        anchor.group.add(object);
        object.scale.set(
          GLOBAL_SCALE_WELL,
          GLOBAL_SCALE_WELL,
          GLOBAL_SCALE_WELL
        );
        object.translateX(GLOBAL_TRANSLATION);
        object.rotation.set(GROUP_ROTATION, 0, 0);
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      (error) => {
        console.log(error);
      }
    );

    // CONTACT LENS
    fbxLoader.load(
      "AR_Alcon_Contact_Lens_v3.fbx",
      (object) => {
        object.traverse(function (child) {
          if (child.isMesh && child.material) {
            child.transparent = true;
            video = document.getElementById("contact_lens");

            texture = new THREE.VideoTexture(video);
            texture.colorSpace = THREE.SRGBColorSpace;

            child.material = new THREE.MeshStandardMaterial({
              color: 0x2a4655,
              map: texture,
              metalness: 0,
              roughness: 0.5,
            });
            child.material.transparent = true;
            child.material.depthWrite = false;
            child.material.depthTest = false;
            child.material.side = THREE.DoubleSide;
            child.material.opacity = 0;
            child.material.alphaMap = null;

            let mixer = new THREE.AnimationMixer(child);

            animationMixers.push(mixer);

            const opacityKF = new THREE.NumberKeyframeTrack(
              ".material.opacity",
              [5, 6, 19, 20],
              [0, 0.95, 0.9, 0]
            );

            const fadeLensClip = new THREE.AnimationClip(
              `fade-contact-${child.id}`,
              -1,
              [opacityKF]
            );

            animationActions.push(mixer.clipAction(fadeLensClip));
          }
        });

        let mixer = new THREE.AnimationMixer(object);
        animationMixers.push(mixer);

        const animationAction = mixer.clipAction(object.animations[0]);
        animationActions.push(animationAction);
        animationMixers.push(mixer);

        anchor.group.add(object);
        object.scale.set(GLOBAL_SCALE, GLOBAL_SCALE, GLOBAL_SCALE);
        object.translateX(GLOBAL_TRANSLATION);
        object.rotation.set(GROUP_ROTATION, 0, 0);
        object.translateY(0.16);
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      (error) => {
        console.log(error);
      }
    );

    // WATER DROP
    fbxLoader.load(
      "AR_Alcon_Water_Drop.fbx",
      (object) => {
        object.traverse(function (child) {
          if (child.isMesh && child.material) {
            child.material.transparent = true;
            child.material.depthWrite = false;
            child.material.side = THREE.DoubleSide;

            child.material.opacity = 0.5;
          }
        });

        let mixer = new THREE.AnimationMixer(object);
        animationMixers.push(mixer);

        const animationAction = mixer.clipAction(object.animations[0]);
        animationActions.push(animationAction);
        animationMixers.push(mixer);

        anchor.group.add(object);
        object.scale.set(GLOBAL_SCALE, GLOBAL_SCALE, GLOBAL_SCALE);
        object.translateX(GLOBAL_TRANSLATION);
        object.rotation.set(GROUP_ROTATION, 0, 0);
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      (error) => {
        console.log(error);
      }
    );

    fbxLoader.load(
      "AR_Alcon_Bubbles_v2.fbx",
      (object) => {
        let mixer = new THREE.AnimationMixer(object);
        animationMixers.push(mixer);

        const animationAction = mixer.clipAction(object.animations[0]);
        animationActions.push(animationAction);
        animationMixers.push(mixer);

        object.traverse(function (child) {
          if (child.isMesh && child.material) {
            let mixer = new THREE.AnimationMixer(child);
            child.material.transparent = true;
            child.material.depthWrite = false;
            child.material.side = THREE.DoubleSide;
            child.material.opacity = 0;
            child.material.alphaMap = null;

            animationMixers.push(mixer);

            const opacityKF = new THREE.NumberKeyframeTrack(
              ".material.opacity",
              [2, 2.1, 43, 44],
              [0, 0.25, 0.25, 0]
            );

            const fadeBubbleClip = new THREE.AnimationClip(
              `fade-bubble-${child.id}}`,
              object.animations[0].duration,
              [opacityKF]
            );

            animationActions.push(mixer.clipAction(fadeBubbleClip));
          }
        });

        object.transparent = true;

        anchor.group.add(object);
        object.position.set(0, 0, -0.25);
        object.scale.set(GLOBAL_SCALE, GLOBAL_SCALE, GLOBAL_SCALE);
        object.translateX(GLOBAL_TRANSLATION);
        object.rotation.set(GROUP_ROTATION, 0, 0);
        object.rotateY((180 * Math.PI) / 180);
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      (error) => {
        console.log(error);
      }
    );

    fbxLoader.load(
      "AR_Alcon_Typography_SOMOS.fbx",
      (object) => {
        object.traverse(function (child) {
          if (child.isMesh && child.material) {
            child.material.transparent = false;
          }
        });

        let mixer = new THREE.AnimationMixer(object);
        animationMixers.push(mixer);

        const animationAction = mixer.clipAction(object.animations[0]);
        animationAction._clip.name = "somos_type";
        animationActions.push(animationAction);
        animationMixers.push(mixer);

        let mixer2 = new THREE.AnimationMixer(object.children[0].children[0]);
        object.children[0].children[0].material.opacity = 0;
        object.children[0].children[0].material.alphaMap = null;
        object.children[0].children[0].material.transparent = true;
        object.children[0].children[0].material.side = THREE.FrontSide;

        animationMixers.push(mixer2);

        const opacityKF = new THREE.NumberKeyframeTrack(
          ".material.opacity",
          [0, 9.5, 10.5, 12, 13],
          [0, 0, 1, 1, 0]
        );

        const depthKF = new THREE.BooleanKeyframeTrack(
          ".material.depthWrite",
          [0, 9.5, 10.5, 12, 13],
          [false, true, true, true, false]
        );

        const fadeWaterClip = new THREE.AnimationClip(
          "fade-somos-opacity",
          object.animations[0].duration,
          [opacityKF, depthKF]
        );

        animationActions.push(mixer2.clipAction(fadeWaterClip));

        anchor.group.add(object);
        object.scale.set(GLOBAL_SCALE, GLOBAL_SCALE, GLOBAL_SCALE);
        object.translateX(GLOBAL_TRANSLATION);
        object.rotation.set(GROUP_ROTATION, 0, 0);
        object.translateY(-0.2);
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      (error) => {
        console.log(error);
      }
    );

    fbxLoader.load(
      "AR_Alcon_Typography_AGUA_PRT.fbx",
      (object) => {
        object.traverse(function (child) {
          if (child.isMesh && child.material) {
            child.material.transparent = false;
          }
        });

        let mixer = new THREE.AnimationMixer(object);
        animationMixers.push(mixer);

        const animationAction = mixer.clipAction(object.animations[0]);
        animationAction._clip.name = "agua_type";
        animationActions.push(animationAction);
        animationMixers.push(mixer);

        let mixer2 = new THREE.AnimationMixer(
          object.children[0].children[0].children[0]
        );
        object.children[0].children[0].children[0].material.opacity = 0;
        object.children[0].children[0].children[0].material.alphaMap = null;
        object.children[0].children[0].children[0].material.transparent = true;
        object.children[0].children[0].children[0].material.side =
          THREE.FrontSide;
        animationMixers.push(mixer2);

        const opacityKF = new THREE.NumberKeyframeTrack(
          ".material.opacity",
          [0, 10, 11, 12, 13],
          [0, 0, 1, 1, 0]
        );

        const depthKF = new THREE.BooleanKeyframeTrack(
          ".material.depthWrite",
          [0, 10, 11, 12, 13],
          [false, true, true, true, false]
        );

        const fadeWaterClip = new THREE.AnimationClip(
          "fade-agua-opacity",
          object.animations[0].duration,
          [opacityKF, depthKF]
        );

        animationActions.push(mixer2.clipAction(fadeWaterClip));

        anchor.group.add(object);
        object.scale.set(GLOBAL_SCALE, GLOBAL_SCALE, GLOBAL_SCALE);
        object.translateX(GLOBAL_TRANSLATION);
        object.rotation.set(GROUP_ROTATION, 0, 0);
        object.translateY(-0.2);
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      (error) => {
        console.log(error);
      }
    );

    fbxLoader.load(
      "AR_Alcon_Typography_LENTES_DE_CONTACTO_v2_PRT.fbx",
      (object) => {
        object.traverse(function (child) {
          if (child.isMesh && child.material) {
            child.material.transparent = false;
          }
        });
        let mixer = new THREE.AnimationMixer(object);
        animationMixers.push(mixer);

        const animationAction = mixer.clipAction(object.animations[0]);
        animationAction._clip.name = "lentes_type";

        animationActions.push(animationAction);
        animationMixers.push(mixer);

        let mixer2 = new THREE.AnimationMixer(object.children[0].children[0]);
        object.children[0].children[0].material.opacity = 0;
        object.children[0].children[0].material.alphaMap = null;
        object.children[0].children[0].material.transparent = true;
        object.children[0].children[0].material.side = THREE.FrontSide;

        animationMixers.push(mixer2);

        const opacityKF = new THREE.NumberKeyframeTrack(
          ".material.opacity",
          [0, 14.5, 15, 17, 17.5],
          [0, 0, 1, 1, 0]
        );

        const depthKF = new THREE.BooleanKeyframeTrack(
          ".material.depthWrite",
          [0, 14.5, 15, 17, 17.5],
          [false, true, true, true, false]
        );

        const fadeWaterClip = new THREE.AnimationClip(
          "fade-agua-opacity",
          object.animations[0].duration,
          [opacityKF, depthKF]
        );

        animationActions.push(mixer2.clipAction(fadeWaterClip));

        anchor.group.add(object);
        object.scale.set(GLOBAL_SCALE, GLOBAL_SCALE, GLOBAL_SCALE);
        object.translateX(GLOBAL_TRANSLATION);
        object.rotation.set(GROUP_ROTATION, 0, 0);
        // object.position.set(0.21, -0.96, 0.25)
        object.position.set(0.31, -0.6, 0.3);
        object.translateY(0.25);
        object.translateY(0.1);
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      (error) => {
        console.log(error);
      }
    );

    fbxLoader.load(
      "AR_Alcon_Typography_DE_SUPERFICIE_ACUOSA_v2_PRT.fbx",
      (object) => {
        console.log("🚀 ~ object:", object);
        object.traverse(function (child) {
          console.log(child);
          if (child.isMesh && child.material) {
            child.material.transparent = false;
          }
        });
        let mixer = new THREE.AnimationMixer(object);
        animationMixers.push(mixer);

        const animationAction = mixer.clipAction(object.animations[0]);
        animationAction._clip.name = "acuosa_type";
        animationActions.push(animationAction);
        animationMixers.push(mixer);

        let mixer2 = new THREE.AnimationMixer(
          object.children[0].children[0].children[0]
        );
        object.children[0].children[0].children[0].material.opacity = 0;
        object.children[0].children[0].children[0].material.alphaMap = null;
        object.children[0].children[0].children[0].material.transparent = true;
        object.children[0].children[0].children[0].material.side =
          THREE.FrontSide;
        animationMixers.push(mixer2);

        const opacityKF = new THREE.NumberKeyframeTrack(
          ".material.opacity",
          [0, 14.7, 15.7, 17, 17.5],
          [0, 0, 1, 1, 0]
        );

        const depthKF = new THREE.BooleanKeyframeTrack(
          ".material.depthWrite",
          [0, 14.7, 15.7, 17, 17.5],
          [false, true, true, true, false]
        );

        const fadeWaterClip = new THREE.AnimationClip(
          "fade-agua-opacity",
          object.animations[0].duration,
          [opacityKF, depthKF]
        );

        animationActions.push(mixer2.clipAction(fadeWaterClip));

        anchor.group.add(object);
        object.scale.set(GLOBAL_SCALE, GLOBAL_SCALE, GLOBAL_SCALE);
        object.translateX(GLOBAL_TRANSLATION);
        object.rotation.set(GROUP_ROTATION - (45 * Math.PI) / 180, 0, 0);
        object.position.set(-0.23, -0.9, 0.08);
        object.translateY(0.1);
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      (error) => {
        console.log(error);
      }
    );

    const pointLight = new THREE.PointLight(0xffffff, 2);
    pointLight.position.set(0, -2, 5);

    const secondaryLight = new THREE.DirectionalLight(0xffffff, 1);
    const targetObject = new THREE.Object3D();
    targetObject.translateZ(-3);
    targetObject.translateY(10);
    anchor.group.add(targetObject);

    secondaryLight.target = targetObject;

    anchor.group.add(pointLight);
    anchor.group.add(secondaryLight);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.8);
    anchor.group.add(ambientLight);

    start();

    return () => {
      mindarThreeRef.current.renderer.setAnimationLoop(null);
      mindarThreeRef.current.stop();
    };
  }, []);

  return (
    <div style={{ width: "100%", height: "100%" }} ref={containerRef}></div>
  );
};
